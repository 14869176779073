<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column prop="name" label="姓名" >
      </el-table-column>
      <el-table-column prop="mobile_phone" label="手机" >
      </el-table-column>
      <el-table-column prop="position_name" label="职务" >
      </el-table-column>
      <el-table-column prop="mode" label="状态" >
        <template #default="scope">
           <span v-for="(item,i) in statusArr">
             <span v-if="item.id==scope.row.status">{{item.name}}</span>
           </span>
        </template>
      </el-table-column>

      <el-table-column width="170" prop="create_time" label="创建时间" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="125">
        <template #header>
          <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input  v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input  v-model="ruleForm.idcard"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile_phone">
          <el-input  v-model="ruleForm.mobile_phone"></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="position_id">
          <el-select filterable style="width: 100%"  v-model="ruleForm.position_id" >
            <el-option
                v-for="item in positionArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级人员" prop="higher_emp_id">
          <el-select
              style="width: 100%"
              v-model="ruleForm.higher_emp_id"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="输入姓名、手机号查询"
              :remote-method="onhigher"
              :loading="loading">
            <el-option
                v-for="item in higherArr"
                :key="item.id"
                :label="item.name+'-'+item.mobile_phone"
                :value="item.id">
            </el-option>
          </el-select>
<!--          <el-select filterable  clearable style="width: 100%"  v-model="ruleForm.higher_emp_id" >-->
<!--            <el-option-->
<!--                v-for="item in higherArr"-->
<!--                :key="item.id"-->
<!--                :label="item.name+'-'+item.mobile_phone"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-form-item>
        <el-form-item label="在职状态" prop="status">
          <el-select style="width: 100%"  v-model="ruleForm.status" >
            <el-option
                v-for="item in statusArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联用户" prop="mode">
          <el-select
              style="width: 100%"
              v-model="ruleForm.member_id"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="输入姓名、手机号查询"
              :remote-method="remoteMethod"
              :loading="loading">
            <el-option
                v-for="item in memberArr"
                :key="item.id"
                :label="item.nickname+'-'+item.mobile_phone"
                :value="item.id">
            </el-option>
          </el-select>
<!--          <el-select filterable  clearable style="width: 100%" @change="modefun" v-model="ruleForm.member_id" >-->
<!--            <el-option-->
<!--                v-for="item in memberArr"-->
<!--                :key="item.id"-->
<!--                :label="item.nickname+'-'+item.mobile_phone"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-form-item>
        <el-form-item v-if="ruleForm.member_id" label="提现规则" prop="">
          <el-select style="width: 100%" clearable  v-model="ruleForm.withdraw_rule_id" >
            <el-option
                v-for="item in withdrawArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.member_id" label="分销等级" prop="grade_id">
          <el-select filterable  style="width: 100%"  v-model="ruleForm.grade_id" >
            <el-option
                v-for="item in gradeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
export default {
  name: 'Grade',
  data(){
    return{
      skuValName:'',
      loading:false,
      login:false,
      drawer:false,
      tableData:[],
      withdrawArr:[],//规则
      memberArr:[],//用户
      higherArr:[],//上级
      gradeArr:[],//分销
      positionArr:[],//职务
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        idcard:'',
        mobile_phone:'',
        position_id:'',
        higher_emp_id:'',
        status:0,
        member_id:'',
        withdraw_rule_id:'',
        grade_id:'',
      },
      statusArr:[
        {
          id:0,
          name:'在职',
        },
        {
          id:1,
          name:'离职',
        },
      ],
      rules:{
        name: [
          { required: true, message: '请填写姓名', trigger: 'blue' },
        ],
        idcard: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        mobile_phone: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        position_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        withdraw_rule_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.onTable()
    this.onwithdrawArr()
    this.onmemberArr()
    this.ongradeArr()
    this.keyupSubmit()
    this.onpositionArr()
    this.onhigher()
  },
  methods:{

    /*远程搜索*/
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let form={
              page:1,
              limit:500,
              w_type:'base',
              keywords:query
            }
            axios.getSkuData(form,'/api/admin/member').then((res)=>{
                this.memberArr=res.data.data
              this.loading = false;
            }).catch((err)=>{
              this.loading = false;
            })
      } else {
        this.onmemberArr()
      }
    },
    onhigher(query){
      let form={
        page:1,
        limit:200,
        keywords:query
      }
      this.loading = true;
      axios.getSkuData(form,'/api/admin/emp').then((res)=>{
        console.log(res)
        this.higherArr=JSON.parse(JSON.stringify(res.data.data))
        this.higherArr.unshift({
          name:'顶层',
          id:0,
          mobile_phone:'',
        })
        console.log('this.tableData',this.tableData)
        this.loading = false;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    /*远程搜索*/
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onwithdrawArr(){
      let form={
        page:1,
        limit:1000,
      }
      axios.getSkuData(form,'/api/admin/member/withdraw').then((res)=>{
        console.log(res)
        this.withdrawArr=res.data.data
      }).catch((err)=>{

      })
    },
    onmemberArr(){
      let form={
        page:1,
        limit:500,
        w_type:'base',
      }
      axios.getSkuData(form,'/api/admin/member').then((res)=>{
        this.memberArr=res.data.data
      }).catch((err)=>{

      })
    },
    ongradeArr(){
      let form={
        page:1,
        limit:700,
      }
      axios.getSkuData(form,'/api/admin/member/grade').then((res)=>{
        this.gradeArr=res.data.data
      }).catch((err)=>{

      })
    },
    onpositionArr(){
      let form={
        page:1,
        limit:700,
      }
      axios.getSkuData(form,'/api/admin/emp/position').then((res)=>{
        this.positionArr=res.data.data
      }).catch((err)=>{

      })
    },
    modefun(){
      this.ruleForm.withdraw_rule_id=''
      this.ruleForm.grade_id=''
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getSkuData(form,'/api/admin/emp').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },

    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },


    add(){
      this.ruleForm={
        name:'',
        idcard:'',
        mobile_phone:'',
        position_id:'',
        higher_emp_id:'',
        status:0,
        member_id:'',
        withdraw_rule_id:'',
        grade_id:'',
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.idcard=row.idcard
      this.ruleForm.mobile_phone=row.mobile_phone
      this.ruleForm.position_id=row.position_id
      this.ruleForm.higher_emp_id=row.higher_emp_id
      this.ruleForm.status=row.status
      this.ruleForm.member_id=row.member_id
      this.ruleForm.withdraw_rule_id=row.withdraw_rule_id
      this.ruleForm.grade_id=row.grade_id
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postSkuData(this.ruleForm,'/api/admin/emp/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postSkuData(this.ruleForm,'/api/admin/emp/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postSkuData({id:row.id},'/api/admin/emp/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
</style>